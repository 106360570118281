import React, {FC} from 'react'
import clsx from 'clsx'
import {useLayout} from '../../core/LayoutProvider'
import {DefaultTitle} from '../header/page-title/DefaultTitle'

const Toolbar: FC = () => {
  const {classes} = useLayout()
  return (
    <div className='toolbar' id='kt_toolbar'>
      {/* begin::Container */}
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
      >
        <DefaultTitle />
      </div>
      {/* end::Container */}
    </div>
  );
  
}

export {Toolbar}
