import axios, {AxiosResponse} from "axios";
import Cookies from "js-cookie";
import GutenbergRequest from "../interfaces/GutenbergRequest";
import MachiavelliRequest from "../interfaces/MachiavelliRequest";

async function ShAuthorizationRefresh(): Promise<void> {

    const tokenExpiration = localStorage.getItem('shTokenExpiration') ?? '';

    const isOneHourBeforeExpiration = () => {
        const expirationDate = new Date(tokenExpiration);
        const currentTime = new Date();

        const timeDifference = expirationDate.getTime() - currentTime.getTime();
        return timeDifference <= 3600000; //refresh 1 hour before expiry
    };

    if (!isOneHourBeforeExpiration()) {
        return;
    }

    const clientId = localStorage.getItem('shClientId') ?? '';

    try {
        const response = await axios.request({
            method: 'POST',
            url: 'api/v1/refresh',
            baseURL: process.env.REACT_APP_AUTHORIZATION_API_URL,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
                Authorization: 'Bearer ' + (localStorage.getItem('shToken') ?? ''),
            },
            data: { client_id: clientId },
        });

        localStorage.setItem('shToken', response.data.access_token);
        localStorage.setItem('shTokenExpiration', response.data.expires_at);

        return;
    } catch (error) {
        console.error(error);
        return;
    }

}

const ShAuthorizationClient: (props: MachiavelliRequest) => Promise<AxiosResponse> = async (props: MachiavelliRequest) => {

    await ShAuthorizationRefresh();

    const uri = props.uri ?? "";
    const method = props.method ?? "get";
    const token = localStorage.getItem('shToken') ?? '';
    const data = props.data ?? undefined;

    const apiClient = axios.create({
        baseURL: process.env.REACT_APP_AUTHORIZATION_API_URL,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-CSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
        }
    });

    const authHeader = () => {
        return {Authorization: 'Bearer ' + token};
    }

    return apiClient.request({
            method: method,
            url: uri,
            data: data,
            headers: authHeader(),
        }
    );

}
const MachiavelliClient: (props: MachiavelliRequest) => Promise<AxiosResponse> = async (props: MachiavelliRequest) => {

    await ShAuthorizationRefresh();

    const uri = props.uri ?? "";
    const method = props.method ?? "get";
    const token = localStorage.getItem('shToken') ?? '';
    const tokenExpiration = localStorage.getItem('shTokenExpiration') ?? '';
    const data = props.data ?? undefined;

    const apiClient = axios.create({
        baseURL: process.env.REACT_APP_MACHIAVELLI_API_URL,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    });

    const authHeader = () => {
        return {Authorization: 'Bearer ' + token};
    }

    if (method.toUpperCase() === "POST") {
        return apiClient.post(uri, data, {
                headers: authHeader()
            }
        )
    }
    if (method.toUpperCase() === "PATCH") {
        return apiClient.patch(uri, data, {
                headers: authHeader()
            }
        )
    }
    if (method.toUpperCase() === "DELETE") {
        return apiClient.delete(uri, {
                headers: authHeader()
            }
        )
    }

    return apiClient.get(uri, {
        headers: authHeader()
    });

}

const GutenbergClient: (props: GutenbergRequest) => Promise<AxiosResponse> = async (props: GutenbergRequest) => {

    await ShAuthorizationRefresh();


    const uri = props.uri ?? "";
    const method = props.method ?? "get";
    const token = localStorage.getItem('shToken') ?? '';
    const tokenExpiration = localStorage.getItem('shTokenExpiration') ?? '';
    const data = props.data ?? undefined;

    const apiClient = axios.create({
        baseURL: process.env.REACT_APP_GUTENBERG_API_URL,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    });

    const authHeader = () => {
        return {Authorization: 'Bearer ' + token};
    }

    // const csrf = async () => {
    //     return apiClient.get('XSRF-TOKEN')
    // }
    //
    // return csrf().then(() => {

    if (method.toUpperCase() === "POST") {
        return apiClient.post(uri, data, {
                headers: authHeader()
            }
        )
    }
    if (method.toUpperCase() === "PATCH") {
        return apiClient.patch(uri, data, {
                headers: authHeader()
            }
        )
    }
    if (method.toUpperCase() === "DELETE") {
        return apiClient.delete(uri, {
                headers: authHeader()
            }
        )
    }

    return apiClient.get(uri, {
        headers: authHeader()
    });
    // });
}

export default {MachiavelliClient, GutenbergClient, ShAuthorizationClient};
