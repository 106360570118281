import React, {FC, lazy, Suspense} from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { HasRolePermission, PERMISSIONS } from '../modules/auth/components/RolesRouting'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'

const PrivateRoutes: React.FC = () => {
    const GutenbergPage = lazy(() => import('../pages/gutenberg/GutenbergWrapper'))
    const MachiavelliPage = lazy(() => import('../pages/machiavelli/MachiavelliWrapper'))
    const AkeneoPage = lazy(() => import('../pages/akeneo/AkeneoWrapper'))
    const AssistantPage = lazy(() => import('../pages/assistant/AssistantWrapper'))

    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
    const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
    const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
    const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

    return (
        <Routes>
            <Route element={<MasterLayout/>}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/dashboard'/>}/>
                {/* Pages */}
                <Route path='dashboard' element={<DashboardWrapper/>}/>
                {/* Lazy Modules */}

                <Route
                    path='gutenberg/*'
                    element={
                        <HasRolePermission required_permissions={PERMISSIONS.testorders.read}>
                            <SuspensedView><GutenbergPage /></SuspensedView>
                        </HasRolePermission>
                    }
                />
                <Route
                    path='machiavelli/*'
                    element={
                        <HasRolePermission required_permissions={PERMISSIONS.virtualproducts.read}>
                            <SuspensedView><MachiavelliPage/></SuspensedView>
                        </HasRolePermission>
                    }
                />
                <Route
                    path='akeneo/*'
                    element={
                        <HasRolePermission required_permissions={[PERMISSIONS.eanlist.read, PERMISSIONS.attributerelations.read, PERMISSIONS.rules.read]}>
                            <SuspensedView><AkeneoPage/></SuspensedView>
                        </HasRolePermission>
                    }
                />
                <Route
                    path='assistant/*'
                    element={
                        <HasRolePermission required_permissions={[PERMISSIONS.assistant.read]}>
                            <SuspensedView><AssistantPage/></SuspensedView>
                        </HasRolePermission>
                    }
                />
                <Route
                    path='crafted/widgets/*'
                    element={
                        <SuspensedView>
                            <WidgetsPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/account/*'
                    element={
                        <SuspensedView>
                            <AccountPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='apps/chat/*'
                    element={
                        <SuspensedView>
                            <ChatPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='apps/user-management/*'
                    element={
                        <SuspensedView>
                            <UsersPage/>
                        </SuspensedView>
                    }
                />
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404'/>}/>
            </Route>
        </Routes>
    )
}

const SuspensedView: FC = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
