/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {HasRolePermission, PERMISSIONS} from '../../../../app/modules/auth/components/RolesRouting';
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
    const intl = useIntl()
    const [version, setVersion] = useState('');

    useEffect(() => {
        setVersion("" + process.env.REACT_APP_VERSION?.toString());
    }, [process.env])

    return (
        <>
            <AsideMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/art/art002.svg'
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                fontIcon='bi-app-indicator'
            />

            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span
                        className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.CONTENT'})}</span>
                </div>
            </div>

            <HasRolePermission required_permissions={PERMISSIONS.eanlist.read}>
                <AsideMenuItem
                    to='/akeneo/create-ean-list'
                    title={intl.formatMessage({id: 'MENU.CREATEEANLIST'})}
                    fontIcon='bi-archive'
                />
            </HasRolePermission>

            <HasRolePermission required_permissions={PERMISSIONS.virtualproducts.read}>
                <AsideMenuItem
                    to='/machiavelli/create-virtual-product'
                    title={intl.formatMessage({id: 'MENU.CREATEVIRTUALPRODUCTS'})}
                    fontIcon='bi-archive'
                />
            </HasRolePermission>

            <HasRolePermission required_permissions={PERMISSIONS.attributerelations.read}>
                <AsideMenuItem
                    to='/akeneo/attribute-relations'
                    title='Attribute Relations'
                    fontIcon='bi-archive'
                />
            </HasRolePermission>

            <HasRolePermission required_permissions={PERMISSIONS.rules.read}>
                <AsideMenuItem
                    to='/akeneo/rules-list'
                    title='Rules'
                    fontIcon='bi-archive'
                />
            </HasRolePermission>

            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span
                        className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.ASSISTANT'})}</span>
                </div>
            </div>

            <HasRolePermission required_permissions={PERMISSIONS.assistant.read}>
                <AsideMenuItem
                    to='/assistant/list'
                    title='Assistants'
                    fontIcon='bi-archive'
                />
            </HasRolePermission>

            <HasRolePermission required_permissions={PERMISSIONS.assistant.read}>
                <AsideMenuItem
                    to='/assistant/import/list'
                    title='Import list'
                    fontIcon='bi-archive'
                />
            </HasRolePermission>

            <HasRolePermission required_permissions={PERMISSIONS.assistant.read}>
                <AsideMenuItem
                    to='/assistant/export/list'
                    title='Result list'
                    fontIcon='bi-archive'
                />
            </HasRolePermission>
            <HasRolePermission required_permissions={PERMISSIONS.assistant.read}>
                <AsideMenuItem
                    to='/assistant/glossary/list'
                    title='Glossary list'
                    fontIcon='bi-archive'
                />
            </HasRolePermission>

            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span
                        className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.B2B'})}</span>
                </div>
            </div>

            <HasRolePermission required_permissions={PERMISSIONS.colorlabbulkdesigner.read}>
                <AsideMenuItem
                    to='/machiavelli/colorlab-bulk-designer'
                    title={intl.formatMessage({id: 'MENU.COLORLABBULKDESIGNER'})}
                    fontIcon='bi-archive'
                />
            </HasRolePermission>

            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span
                        className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.TESTTOOL'})}</span>
                </div>
            </div>

            <HasRolePermission required_permissions={PERMISSIONS.testorders.read}>
                <AsideMenuItem
                    to='/gutenberg/overview'
                    title={intl.formatMessage({id: 'MENU.CREATETESTORDERS'})}
                    fontIcon='bi-archive'
                />
            </HasRolePermission>

            <div className='menu-item'>
                <div className='menu-content'>
                    <div className='separator mx-1 my-4'></div>
                </div>
            </div>
            <div className='menu-item'>
                <p className='menu-link'>
                    <span className='menu-title'>{intl.formatMessage({id: 'MENU.APPVERSION'})} {version}</span>
                </p>
            </div>
        </>
    )
}
