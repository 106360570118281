import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import API from '../../../services/api'

export function Login(): JSX.Element {
    const [loading, setLoading] = useState(false)
    const {saveAuth, setCurrentUser} = useAuth()

    const handleFailure = async () => {
        console.log('Failed to login');
    }

    useEffect(() => {
        setLoading(true);
        try {

            const params = new URLSearchParams(location.search);
            const accessToken = params.get('access_token');
            const expiresAt = params.get('expires_at');
            const clientId = params.get('client_id');

            if (!accessToken || !expiresAt || !clientId) {
                console.log('Unauthenticated code:a');
                return
            }

            localStorage.setItem('shToken', accessToken);
            localStorage.setItem('shClientId', clientId);
            localStorage.setItem('shTokenExpiration', expiresAt);

            API.ShAuthorizationClient({
                uri: 'api/v1/user',
                method: 'get',
            }).then(response => {
                if (response.status !== 200) {
                    return Promise.reject(response);
                }

                setCurrentUser({
                    id: response.data.id,
                    username: response.data.email,
                    email: response.data.email,
                    fullname: response.data.name,
                    pic: response.data.avatar,
                    roles: response.data.scopes,
                    language: 'en',
                });
                localStorage.setItem('shUser', JSON.stringify(response.data));
                localStorage.setItem('clientEmail', response.data.email);

            }).catch(e => {
                saveAuth(undefined);
                localStorage.removeItem('clientEmail');
                localStorage.removeItem('shToken');
                localStorage.removeItem('shClientId');
                localStorage.removeItem('shTokenExpiration');
                // onErrors(e.response.data.errors ?? [[e.response.data.message]])
            });

            setLoading(false);
        } catch (error) {
            console.log("ERROR");
            console.error(error);

            API.ShAuthorizationClient({uri: 'api/v1/logout', method: 'get'});

            saveAuth(undefined);
            //setStatus('The login detail is incorrect')
            setLoading(false)
        }
    }, []);

    const handleLogout = async (googleData: any): Promise<void> => {
        console.log("Logging out!");
        setLoading(false);
    }

    return (
        <div
            className='login'
        >
            {/* begin::Heading */}
            <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>Inloggen op DaVinci</h1>
                <div className='text-gray-400 fw-bold fs-4'>
                    Klaar met werken?{' '}
                    <Link to='/auth/logout' className='link-primary fw-bolder'>
                        Altijd uitloggen!
                    </Link>
                </div>
            </div>
            {/* begin::Heading */}

            <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>
                    Het is alleen mogelijk om via je werk e-mail in te loggen. Selecteer het juiste adres in het
                    volgende scherm!
                </div>
            </div>

            {/* begin::Action */}
            <div className='text-center'>

                <a href={process.env.REACT_APP_AUTHORIZATION_API_URL + `/login`} className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
                    <div role="button" aria-labelledby="button-label"
                         className="nsm7Bb-HzV7m-LgbsSe  hJDwNd-SxQuSe i5vt6e-Ia7Qfc uaxL4e-RbRzK">
                        <div className="nsm7Bb-HzV7m-LgbsSe-MJoBVe"></div>
                        <div className="nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb">
                            <div className="nsm7Bb-HzV7m-LgbsSe-Bz112c">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"
                                     className="LgbsSe-Bz112c">
                                    <g>
                                        <path fill="#EA4335"
                                              d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                        <path fill="#4285F4"
                                              d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                        <path fill="#FBBC05"
                                              d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                        <path fill="#34A853"
                                              d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                        <path fill="none" d="M0 0h48v48H0z"></path>
                                    </g>
                                </svg>
                            </div>
                            <span className="nsm7Bb-HzV7m-LgbsSe-BPrWId">Inloggen met Google</span><span
                            className="L6cTce" id="button-label">Inloggen met Google</span></div>
                    </div>
                </a>


                {/* begin::Google link */}
                {!loading ? (
                    <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/logos/sph.svg')}
                            className='h-20px me-3'
                        />


                    </a>
                ) : (
                    <div className='loading'>
                        ...
                    </div>
                )}
                {/* end::Google link */}

            </div>
            {/* end::Action */}
        </div>
    )
}
