import React, { Component } from 'react';
import { Route } from 'react-router-dom';

// Load an example payload
import { userRolePermissions } from '../../../helpers/userRolePermissionList';

interface RequireType {
    required_permissions: string[] | string;
}

const PERMISSIONS = {
    eanlist: {
        read: 'davinci.eanlist.read',
        write: 'davinci.eanlist.write',
    },
    virtualproducts: {
        read: 'davinci.virtualproducts.read',
        write: 'davinci.virtualproducts.write',
    },
    attributerelations: {
        read: 'davinci.attributerelations.read',
        write: 'davinci.attributerelations.write',
    },
    rules: {
        read: 'davinci.rules.read',
        write: 'davinci.rules.write',
    },
    colorlabbulkdesigner: {
        read: 'davinci.colorlabbulkdesigner.read',
        write: 'davinci.colorlabbulkdesigner.write',
    },
    testorders: {
        read: 'davinci.testorders.read',
        write: 'davinci.testorders.write',
    },
    assistant: {
        read: 'davinci.assistant.read',
        write: 'davinci.assistant.write',
    },
}

const HasRolePermission: React.FC<RequireType> = ({required_permissions, children}) => {
    const requiredRoles: string[] | string = required_permissions;

    const grantPermission = () => {
        let foundRole = false;

        if(localStorage.getItem('shUser') === null){
            return foundRole;
        }

        const permittedRoles = JSON.parse(localStorage.getItem('shUser') ?? '').scopes;

        if(Array.isArray(requiredRoles)) {
            requiredRoles.forEach((role) => {
                if(permittedRoles.includes(role)) {
                    foundRole = true;
                }
            })
        } else {
            if (permittedRoles.includes(requiredRoles)) {
                foundRole = true;
            }
        }

        return foundRole;
    }

    return (
        <>
            {grantPermission() ? children : null}
        </>
    );
}

export { HasRolePermission, PERMISSIONS }
