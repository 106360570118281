import axios, {AxiosResponse} from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000/api/google"

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`

// Server should return AuthModel
export function login(token: string): Promise<AxiosResponse<AuthModel>> {
    return axios.post<AuthModel>(LOGIN_URL, {
        token,
        //password,
    })
}

export function getUserByToken(token: string): Promise<AxiosResponse<UserModel>> {
    const userModel = axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
        token: token,
    });

    console.log(userModel);
    return userModel;
}
